import { TranslationPack } from "../lib/types";

export async function loadCommon(locale: string): Promise<TranslationPack> {
  switch (locale) {
    case "de_de":
      return (await import("./common.de_de")).common_de_de();
    case "en_us":
      return (await import("./common.en_us")).common_en_us();
    case "es_es":
      return (await import("./common.es_es")).common_es_es();
    case "fr_fr":
      return (await import("./common.fr_fr")).common_fr_fr();
  }
}