import { NgModule } from "@angular/core";
import { AxiosApiClient } from "@smallstack/axios-api-client";
import { ApiConfigurationService } from "@smallstack/common-components";
import { smallstackApiUrl } from "../../app.subscriptions";
import { ApiClientFactory, CompanyApiClient, ProjectApiClient, ResellerApiClient } from "./services/api-client.factory";

@NgModule({
  providers: [
    {
      provide: ProjectApiClient,
      useFactory: (apiClientFactory: ApiClientFactory) => apiClientFactory.project(),
      deps: [ApiClientFactory]
    },
    {
      provide: CompanyApiClient,
      useFactory: (apiClientFactory: ApiClientFactory) => apiClientFactory.company(),
      deps: [ApiClientFactory]
    },
    {
      provide: ResellerApiClient,
      useFactory: (apiClientFactory: ApiClientFactory) => apiClientFactory.reseller(),
      deps: [ApiClientFactory]
    },
    {
      // this will always be the one for project data since this is the most likely one developers need
      provide: AxiosApiClient,
      useExisting: ProjectApiClient
    }
  ]
})
export class SmallstackApiClientModule {
  constructor(apiConfigurationService: ApiConfigurationService) {
    apiConfigurationService.configuration$.next({
      apiUrl: () => smallstackApiUrl.value
    });
  }
}
